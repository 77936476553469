// extracted by mini-css-extract-plugin
export var authorPageContents = "AuthorPage-module--authorPageContents--2YuRD";
export var authorSection = "AuthorPage-module--authorSection--f0pdD";
export var authorImage = "AuthorPage-module--authorImage--34Fab";
export var name = "AuthorPage-module--name--2TEky";
export var role = "AuthorPage-module--role--sPoUh";
export var bio = "AuthorPage-module--bio--TwnhE";
export var separator = "AuthorPage-module--separator--1F9fM";
export var postsSection = "AuthorPage-module--postsSection--3T-ik";
export var authorPostsHeading = "AuthorPage-module--authorPostsHeading--2kJ3k";
export var archiveTitle = "AuthorPage-module--archiveTitle--1Hfzg";
export var archiveCount = "AuthorPage-module--archiveCount--2znC8";
export var archiveCountNumber = "AuthorPage-module--archiveCountNumber--3rp-9";
export var archiveCountNumberSmaller = "AuthorPage-module--archiveCountNumberSmaller--3Ggjo";
export var authorPosts = "AuthorPage-module--authorPosts--1FoMx";