import React from 'react';
import { Helmet } from 'react-helmet/es/Helmet';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as style from './AuthorPage.module.scss';
import MainLayout from '../../components/MainLayout';
import ListItemPost from '../../components/ListItemPost';
import { getBioTextWithLocale } from '../../utilities';
import { CATEGORIES_URL, AUTHOR_URL } from '../../utilities/urlConstants';
import { pathJoinSafeSlashes } from '../../utilities/pathJoinSafe';
import useOnNewActiveLanguageForced from '../../hooks/useOnNewActiveLanguageForced';

export const query = graphql`
  query (
    $authorId: String!
    $locale: String!
    $topMenuId: String!
    $bottomMenuId: String!
    $footerId: String!
    $socialMenuId: String!
  ) {
    topMenu: wpMenu(id: { eq: $topMenuId }) {
      ...topMenuFields
    }
    bottomMenu: wpMenu(id: { eq: $bottomMenuId }) {
      ...otherMenuFields
    }
    footer: wpMenu(id: { eq: $footerId }) {
      ...otherMenuFields
    }
    socialMenu: wpMenu(id: { eq: $socialMenuId }) {
      ...otherMenuFields
    }
    wpUser(id: { eq: $authorId }) {
      id
      name
      description
      slug
      hq_avatar {
        hqAvatar {
          id
          imgixImage {
            gatsbyImageData(
              placeholder: BLURRED
              width: 104
              sizes: "104px"
              srcSetMaxWidth: 512
            )
          }
        }
      }
      multilingual_bio {
        multilingualBio {
          bioText
          locale
        }
      }
    }
    allWpPost(
      sort: { fields: dateGmt, order: DESC }
      filter: {
        author: { node: { id: { eq: $authorId } } }
        language: { locale: { eq: $locale } }
      }
    ) {
      nodes {
        id
        title
        featuredImage {
          node {
            altText
            title
            slug
            imgixImageMobile: imgixImage {
              gatsbyImageData(
                width: 110
                height: 138
                placeholder: BLURRED
                sizes: "110px"
                layout: FIXED
                imgixParams: { fit: "crop", crop: "faces,entropy" }
              )
            }
            imgixImage: imgixImage {
              gatsbyImageData(
                width: 200
                placeholder: BLURRED
                sizes: "200px"
                layout: FIXED
              )
            }
          }
        }
        slug
        language {
          locale
          slug
        }
        categories {
          nodes {
            name
            slug
            language {
              locale
            }
          }
        }
        dateGmt
        seo {
          readingTime
        }
      }
      totalCount
    }
    ph10: file(relativePath: { eq: "images/ph-10.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 50
          placeholder: BLURRED
          formats: [AUTO, WEBP]
          breakpoints: [200, 330, 400]
          sizes: "(max-width: 768px) 110px, 200px"
        )
      }
    }
  }
`;

const isDope = process.env.GATSBY_RIDESTORE_ENVIRONMENT === 'dope';

const AuthorPage = (props) => {
  const { data, pageContext } = props;
  const { wpUser, allWpPost, ph10, topMenu, bottomMenu, footer, socialMenu } =
    data;

  const { t } = useTranslation();

  const {
    name,
    hq_avatar: HQAvatar,
    multilingual_bio: multilingualBio,
  } = wpUser;

  const authorImage = HQAvatar?.hqAvatar?.imgixImage?.gatsbyImageData;

  const setNewActiveLanguage = useOnNewActiveLanguageForced(
    pageContext?.locale,
    `${AUTHOR_URL}/${wpUser?.slug}`
  );

  return (
    <MainLayout
      setNewActiveLanguage={setNewActiveLanguage}
      topMenu={topMenu}
      bottomMenu={bottomMenu}
      footer={footer}
      socialMenu={socialMenu}
      hasNoMarginBelow
    >
      {/* htmlAttributes={{ lang: 'en' }} */}
      <Helmet>
        <title>{name}</title>
        <meta
          name="Description"
          content="Ridestore Magazine Gatsby-powered website.."
        />
      </Helmet>
      <div className={style.authorPageContents}>
        <section className={style.authorSection}>
          {authorImage && authorImage?.images && (
            <GatsbyImage
              image={authorImage}
              className={style.authorImage}
              alt={name}
              title={name}
              loading="eager"
              style={{ borderRadius: '50%' }}
            />
          )}
          <div className={style.name}>{name}</div>
          <div className={style.role}>{t('authorPage.roleAuthor')}</div>
          <div className={style.bio}>
            {getBioTextWithLocale(multilingualBio, pageContext.locale)}
          </div>
        </section>
        <div className={style.separator} />
        <section className={style.postsSection}>
          <div className={style.authorPostsHeading}>
            <div className={style.archiveTitle}>{t('authorPage.archive')}</div>
            <div className={style.archiveCount}>
              <div
                className={`${
                  allWpPost?.totalCount?.toString()?.length > 2
                    ? style.archiveCountNumberSmaller
                    : style.archiveCountNumber
                }`}
              >
                {allWpPost?.totalCount}
              </div>
            </div>
          </div>
          <div className={style.authorPosts}>
            {allWpPost?.nodes?.map((post) => {
              const categoryLink = isDope
                ? '/'
                : pathJoinSafeSlashes(
                    CATEGORIES_URL,
                    post?.categories?.nodes?.[0]?.slug
                  );

              return (
                <ListItemPost
                  key={post?.id}
                  title={post?.title}
                  category={post?.categories?.nodes?.[0]?.name}
                  categoryLink={categoryLink}
                  dateGmt={post?.dateGmt}
                  readTime={post?.seo?.readingTime}
                  slug={pathJoinSafeSlashes(post?.slug)}
                  featuredImgData={
                    post?.featuredImage
                      ? post?.featuredImage?.node?.imgixImage?.gatsbyImageData
                      : ph10?.childImageSharp?.gatsbyImageData
                  }
                  featuredImgDataMobile={
                    post?.featuredImage
                      ? post?.featuredImage?.node?.imgixImageMobile
                          ?.gatsbyImageData
                      : ph10?.childImageSharp?.gatsbyImageData
                  }
                  imgAltText={
                    post?.featuredImage?.node?.altText ||
                    post?.featuredImage?.node?.slug
                  }
                  imgHoverTitle={
                    post?.featuredImage?.node?.title ||
                    post?.featuredImage?.node?.slug
                  }
                />
              );
            })}
          </div>
        </section>
      </div>
    </MainLayout>
  );
};

AuthorPage.propTypes = {};

export default AuthorPage;
